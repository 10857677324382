
let defaultState = {
    appState:'loading',
    pageOffset:0,
    currentPage:"home",
    orderBy:'Name',
    orderDirection:'asc',
    language:'es',
    synonyms:{},
    isLoggin:false,
    mustUpdateInfo:false,
    translateDict:{},
    itemsPerPage:28,
    session:null,
    requiredPurchaseOrder:false,
    customer:null,
    customerGifts:[],
    suggestionList:[],
    salesman:null,
    orderTemplate:null,
    classifications:[],
    show_CarModal:false,
    setting:null,
    filters:[],
    filtersObjects:new Map(),
    cartStatus:'view',
    itemUpdate:0,
    itemTimeFlag:0,
    resetFilters:0,
    items_in_map:new Map(),
    items_in_car:[],
    total_in_car:0,
    total_in_car_iva:0,
    viewItemMode:'module',
    items_store:new Map(),
    items:[],
    items_visible:[],
    priceDeal:null,
    DiscountDeal:null,
    favorites: [],
    cat_selected:{children:[]},
    cat_history: [],
    cat_history_temp: [],
    cat_list: [],
    cat_by_name: new Map(),
    cat_by_code: new Map(),
    open_categories: [],
    open_car_modal:false,
    order_list : [],
    order_editable_states:[],
    order_can_liberate: false,
    order_DelAddress:null,
    order_DelCity:null,
    order_DelProvinceCode:null,
    order_DelCountry:null,
    order_DelLocalityCode:null,
    // eslint-disable-next-line
    order_DeliveryDate:moment().format('YYYY-MM-DD'),
    order_ShipMode:null,
    order_DelZipCode:null,
    order_Comment:null,
    order_PayTerm:null,
    order_CenterCost:null,
    isAdmin:false,
    liberatorCodes: [],
    onlyModifiedCodes: [],
    currentOrder:null,
    actionStack : [],
    mutationStack : [],
    vatCodeMap:new Map()
};
let defaultGetters = {
    isRequiredPurchaseOrder:(state)=>{
        return state.requiredPurchaseOrder;
    },
    getItemViewMode:(state)=>{
        return state.viewItemMode;
    },
    getIsLogin:(state)=>{
        return state.isLoggin;
    },
    getReports:(state)=>{
        if(state.setting)
            return state.setting.ReportsRow.filter(x=>!x.fields.isKPI);
        return [];
    },
    getIndicators:(state)=>{
        if(state.setting)
            return state.setting.ReportsRow.filter(x=>x.fields.isKPI);
        return [];
    },
    getReportParameters:state=>reportName=> {
        for (let report of state.setting.ReportsRow.filter(x=>!x.fields.isKPI))
            if (report.fields.ReportName == reportName)
                return report.fields.reportSpec ? report.fields.reportSpec : null;
        return null;
    },
    getIndicatorParameters:state=>reportName=> {
        for (let report of state.setting.ReportsRow.filter(x=>x.fields.isKPI))
            if (report.fields.ReportName == reportName)
                return report.fields.reportSpec ? report.fields.reportSpec : null;
        return null;
    },
    getReportTitle:state=>reportName=>{
        for(let report of state.setting.ReportsRow)
            if(report.fields.ReportName == reportName)
                return report.fields.ReportTitle;
        return 'Unknown Report';
    },
    getSOCustPurchaseOrder:(state)=>{
        if(state.customer && state.customer.CustPurchaseOrder)
            return state.customer.CustPurchaseOrder;
        return null;
    },
    getShowStock:(state)=>{
        if(state.setting)
            return state.setting.ShowStock;
        return false;
    },
    getOrderBy:(state)=>{
        return state.orderBy;
    },
    getOrderDirection:(state)=>{
        return state.orderDirection;
    },
    getFilters:(state)=>{
        return state.filters;
    },
    getCurrentPage:(state)=>{
        return state.currentPage;
    },
    getOffset:(state)=>{
        return state.pageOffset;
    },
    getItemPerPage:(state)=>{
        return state.itemsPerPage;
    },
    getEcofriendlyItems:(state)=>{
        return Array.from(state.items_store.values()).filter(x=>x.Ecofriendly == 1);
    },
    getClassications:(state)=>{
        return state.classifications;
    },
    getCurrentOrder: (state)=>{
        return state.currentOrder;
    },
    getOrderList: (state)=>{
        return state.order_list;
    },
    getOrderEditableStates:(state)=>{
        return state.order_editable_states;
    },
    getAppState: (state)=>{
        return state.appState;
    },
    getItemFilters: (state)=>{
        if(state.setting)
            return state.setting.ItemFilters;
        else
            return [];
    },
    getHomeCategories: (state)=>{
        if(state.setting)
            return state.setting.HomeCategories;
        else
            return [];
    },
    getHomeItems: (state)=>{
        if(state.setting)
            return state.setting.HomeItems;
        else
            return [];
    },
    formatPrice: state=>price=>decimals=>{
        return parseFloat(Math.round(price * 100) / 100).toFixed(decimals);
    },
    showItemPrice: state=>item=>{
        let itemCode;
        if(item.id)
            itemCode = item.id;
        else
            itemCode = item.Code;
        let storeItem = state.items_store.get(itemCode);
        if (storeItem) {
            let price = storeItem.Price;
            if (state.setting.ShowPriceWithIVA)
                if(storeItem.RowTotal)
                    price = storeItem.RowTotal;
                else {
                    if (storeItem.RowNet)
                        price = storeItem.RowNet;
                }
                //console.log('Price Detail', price, storeItem.Price, storeItem.RowNet, storeItem.RowTotal);
            return parseFloat(Math.round(price * 100) / 100).toFixed(2);
        }
        return null;
    },
    getPriceToShow: (state)=>{
        if(state.setting)
            return state.setting.ShowPriceWithIVA;
        else
            return false;
    },
    trSynonyms:state=>value=>{
        for(let synon of state.synonyms){
            if(value.toLowerCase() == synon.Keys.toLowerCase())
                return synon.ReplaceSet.toLowerCase();
        }
        for(let synon of state.synonyms){
            if(synon.Keys.toLowerCase().startsWith(value.toLowerCase()))
                return synon.ReplaceSet.toLowerCase();
        }
        return null;
    },
    trWithParrams:(state,getters)=>(value)=>{
        let trApply = state.translateDict[value[0]];
        if(trApply) {
            for (let i = 1; i <= value.length; i++)
                trApply = trApply.replace(`{{$${i}}}`,getters.tr(value[i]));
            return trApply;
        }
        return value[0];
    },
    tr: state=>value=>{
        let trApply = state.translateDict[value];
        /*if(params)
                console.log('Store Translate',params);*/
        if(trApply)
            return trApply;
        return value;
    },
    getCategoryDataByName:state=>name=>{
        return state.cat_by_name.get(name);
    },
    getCategoryDataByCode:state=>code=>{
        return state.cat_by_code.get(code);
    },
    getopen_categories: (state) => {
        return state.open_categories;
    },
    getCarModal:(state)=>{
        return state.show_CarModal;
    },
    getCarStatus:(state)=>{
        return state.cartStatus;
    },
    getSetting:(state)=>{
        return state.setting;
    },
    getCarAmount:(state)=>{
        if(isNaN(state.total_in_car))
            return 0;
        return state.total_in_car;
    },
    getCarAmountIVA:(state)=>{
        if(isNaN(state.total_in_car_iva))
            return 0;
        return state.total_in_car_iva;
    },
    getSession:(state)=>{
        return state.session;
    },
    getSalesMan:(state)=>{
        return state.salesman;
    },
    getCustomer:(state)=>{
        return state.customer;
    },
    getCustomerGifts:(state)=>{
        // eslint-disable-next-line
        return __lodash__.orderBy(state.customerGifts, state.orderBy,state.orderDirection);
    },
    getSuggestions:(state)=>{
        // eslint-disable-next-line
        return __lodash__.orderBy(state.suggestionList, state.orderBy,state.orderDirection);
    },
    getOrderTemplate:(state)=>{
        return state.orderTemplate;
    },
    getUserOnlyModifier:(state)=>{
        if(state.session)
            return state.session.fields.OnlyModify == true;
        return false;
    },
    getUserIsAdmin:(state)=>{
        return state.isAdmin;
    },
    orderCanLiberate:(state)=>{
        return state.order_can_liberate;
    },
    getItems: (state) =>{
        // eslint-disable-next-line
        return  __lodash__.orderBy(state.items, state.orderBy,state.orderDirection);
    },
    getItemByCode:state=>itemCode=>{
        return state.items_store.get(itemCode);
    },
    getItemsVisibles:(state)=>{
        // eslint-disable-next-line
        return __lodash__.orderBy(state.items_visible, state.orderBy,state.orderDirection);
    },
    /* No deberia estar permitido acceder
        getItemsStore:(state) => {
            return Array.from(state.items_store.values());
        },
        */
    getCategories: (state) =>{
        return state.cat_list;
    },
    getCategoriesMap: (state) =>{
        return state.cat_map;
    },
    getCategorySelected: (state) =>{
        return state.cat_selected;
    },
    getCategoriesHistory: (state) =>{
        return state.cat_history;
    },
    getFavorites: (state) =>{
        return state.favorites;
    },
    getFavoritesItems: (state) =>{
        return state.favorites;
        /*let itemList=[];
            for(let itemCode of state.favorites)
                itemList.push(state.items_store.get(itemCode))
            return itemList;*/
    },
    getItemCar: (state) =>{
        //console.log('items in Car',state.items_in_car)
        return state.items_in_car;
    },
    getItemCarCount: (state) =>{
        return state.items_in_car.length;
    },
    getItemCarAmount: (state) =>{
        let carAmount = 0;
        for(let item of state.items_in_car) {
            let itemPrice = 0;
            if (item.item)
                itemPrice = item.cant * item.item.Price;
            else {
                let itemCache = state.items_store.get(item.id);
                if (itemCache)
                    itemPrice = item.cant * itemCache.Price;
            }
            carAmount += itemPrice;
        }
        return carAmount;
    },
    translate: state=>text=>{
        return state.translateDict[text] || text;
    }

};
let defaultActions = {
    showNotificacion:function ({commit, state, dispatch},notification){
        let defaultBackground = {
            success: "#28a745",
            info:"#00a9c9",
            warning: "#F89406",
            error: "#BD362F",
        };
        if(!notification.type || !defaultBackground[notification.type])
            notification.type = 'info';
        let toastHTML = $(`<span :style="backgroundColor:${defaultBackground[notification.type]}">${notification.message}</span>`).add(`<button class="btn-flat toast-action" onclick="Materialize.Toast.removeAll()">X</button>`);
        Materialize.toast(toastHTML,10000);
    },
    updatePricesBackGroup: async function ({commit,state,getters,dispatch},itemList){
        const transformPrice = function (item){
            let price = item.Price;
            if(store.getters.getPriceToShow)
                price = item.RowTotal;
            else{
                if(item.RowNet)
                    price = item.RowNet;
            }
            item.Price = parseFloat(Math.round((price + 0.00001) * 100) / 100);
        };

        let codes =  [];
        //let orderItems =  __lodash__.orderBy(itemList, state.orderBy,state.orderDirection);
        for(let item of itemList){
            let x = state.items_store.get(item.Code);
            if(x && x["isCalculate"] == false)
                codes.push(x.Code);
        }

        let PersonCode = state.session ? state.session.Code : null;
        let CustomerCode = state.customer ? state.customer.Code : null;
        let sendCodes = codes;
        //console.log('Send In BackgorundPrice',sendCodes)
        if(sendCodes.length > 0) {
            let url = '/ecommerce/api/getPrices';
            let step = 0;
            let sendCodesCounter = 0;
            let itemByRequest = 100;
            let itemToCommits = [];
            while(sendCodesCounter < sendCodes.length){
                let initStep = itemByRequest * step;
                let endStep = initStep + itemByRequest;
                let sendInRequest = sendCodes.slice(initStep,endStep);
                // eslint-disable-next-line
                let response = await axios.post(url, {artCodes:sendInRequest,personCode: PersonCode, customerCode: CustomerCode});
                let responseParsed = response.data;
                for (let newX  of sendInRequest) {
                    let x;
                    if(newX instanceof Object && newX.Code)
                        x = state.items_store.get(newX.Code);
                    else
                        x = state.items_store.get(newX);
                    if(x) {
                        x.isCalculate = true;
                        if (responseParsed[x.Code]) {
                            x.Price = responseParsed[x.Code].Price;
                            x.VATPrice = responseParsed[x.Code].VATPrice;
                            x.RowNet = responseParsed[x.Code].RowNet;
                            x.RowTotal = responseParsed[x.Code].RowTotal;
                            x.visible = true;
                            transformPrice(x);
                        }
                        //state.items_store.set(x.Code, x);
                        //commit('updateSingleItemStore',x);
                        itemToCommits.push(x);
                    }
                    else {
                        // eslint-disable-next-line
                        console.log('Item sin precios investigar',newX.Code,newX);
                    }
                }
                step = step + 1;
                sendCodesCounter = sendCodesCounter + itemByRequest;
            }
            dispatch('updateItemsInStore', itemToCommits);
        }
    },
    updatePrices: async function ({commit,state,getters,dispatch},itemList){
        const transformPrice = function (item){
            let price = item.Price;
            if(store.getters.getPriceToShow)
                price = item.RowTotal;
            else{
                if(item.RowNet)
                    price = item.RowNet;
            }
            item.Price = parseFloat(Math.round((price + 0.00001) * 100) / 100);
        };

        let codes =  [];
        for(let item of itemList){
            let x = state.items_store.get(item.Code);
            if(x && x["isCalculate"] == false)
                codes.push(x.Code);
        }

        let PersonCode = state.session ? state.session.Code : null;
        let CustomerCode = state.customer ? state.customer.Code : null;
        let sendCodes = codes;

        //console.log('Items to Update in BackGroup',sendCodes);
        commit('updateAppState', 'load items');
        if(sendCodes.length > 0) {
            let url = '/ecommerce/api/getPrices';
            let step = 0;
            let sendCodesCounter = 0;
            let itemByRequest = 30;
            let firstPriceCommit = true;
            let itemToCommits = [];

            //Test for UI load more Fasters
            for (let itemCode  of sendCodes) {
                let x = state.items_store.get(itemCode);
                if (x) {
                    x.isCalculate = true;
                    x.visible = true;
                    commit('updateSingleItemStore',x);
                }
            }
            commit('updateItemFlag');
            commit('updateAppState', 'ready');

            // eslint-disable-next-line
            let profilingTest = +uuidv4();
            // eslint-disable-next-line
            console.time(`CalculatingPrice-${profilingTest}`);
            while(sendCodesCounter < sendCodes.length){
                let initStep = itemByRequest * step;
                let endStep = initStep + itemByRequest;
                let sendInRequest = sendCodes.slice(initStep,endStep);
                // eslint-disable-next-line
                let response = await axios.post(url, {artCodes:sendInRequest,personCode: PersonCode, customerCode: CustomerCode});
                let responseParsed = response.data;
                for (let newX  of sendInRequest) {
                    let x;
                    if(newX instanceof Object && newX.Code)
                        x = state.items_store.get(newX.Code);
                    else
                        x = state.items_store.get(newX);
                    if(x) {
                        x.isCalculate = true;
                        if (responseParsed[x.Code]) {
                            x.Price = responseParsed[x.Code].Price;
                            x.VATPrice = responseParsed[x.Code].VATPrice;
                            x.RowNet = responseParsed[x.Code].RowNet;
                            x.RowTotal = responseParsed[x.Code].RowTotal;
                            x.visible = true;
                            transformPrice(x);
                        }
                        //state.items_store.set(x.Code, x);
                        //commit('updateSingleItemStore',x);
                        itemToCommits.push(x);
                    }
                    else {
                        // eslint-disable-next-line
                        console.log('Item sin precios investigar',newX.Code,newX);
                    }
                }
                if(firstPriceCommit) {
                    await dispatch('updateItemsInStore', itemToCommits);
                    itemToCommits = [];
                    firstPriceCommit = false;
                }
                // eslint-disable-next-line
                console.timeEnd('BackGround Load Prices' + step);
                /*console.time('BackGround Sleep Prices'+step)
                    //await sleep(3000);
                    console.timeEnd('BackGround Sleep Prices'+step)*/
                step = step + 1;
                sendCodesCounter = sendCodesCounter + itemByRequest;
            }
            dispatch('updateItemsInStore', itemToCommits);
            // eslint-disable-next-line
            console.timeEnd(`CalculatingPrice-${profilingTest}`);
        }
        commit('updateItemFlag');
        commit('updateAppState', 'ready');
        commit('updateCarAmount',getters);

    },
    updatePrices2: function ({commit,state,getters,dispatch},newItems){
        const transformPrice = function (item){
            let price = item.Price;
            if(store.getters.getPriceToShow)
                price = item.RowTotal;
            else{
                if(item.RowNet)
                    price = item.RowNet;
            }
            item.Price = parseFloat(Math.round((price + 0.00001) * 100) / 100);
        };

        let codes =  [];
        for(let x of newItems) {
            if(x && x["isCalculate"] == false)
                codes.push(x.Code);
        }

        let PersonCode = state.session ? state.session.Code : null;
        let CustomerCode = state.customer ? state.customer.Code : null;
        let sendCodes = codes;
        if(sendCodes.length > 0) {
            let url = '/ecommerce/api/getPrices';
            //console.time('CalculatingPrice');
            commit('updateAppState','load items');
            // eslint-disable-next-line
            axios.post(url, {artCodes: sendCodes, personCode: PersonCode, customerCode: CustomerCode})
                .then((response) => {
                    let responseParsed = response.data;
                    for (let newX  of newItems) {
                        let x = state.items_store.get(newX.Code);
                        x.isCalculate = true;
                        if (responseParsed[x.Code]) {
                            x.Price = responseParsed[x.Code].Price;
                            x.VATPrice = responseParsed[x.Code].VATPrice;
                            x.RowNet = responseParsed[x.Code].RowNet;
                            x.RowTotal = responseParsed[x.Code].RowTotal;
                            x.visible = true;
                            transformPrice(x);
                            state.items_store.set(x.Code, x);
                            //commit('updateSingleItemStore',x);
                        }
                        else{
                            // eslint-disable-next-line
                            console.log('Item sin precios investigar');
                        }

                    }

                    //console.log('From Price',newItems);
                    commit('updateItemFlag');
                    commit('updateCarAmount',getters);
                    commit('updateAppState', 'ready');
                })
                .catch((err) => {
                    // eslint-disable-next-line
                    console.log('Error in GetPrice', err);
                    commit('updateAppState','ready');
                    commit('updateItemFlag');
                });
        }

    },
    beforeChangeItems: function ({commit,state,getters,dispatch},newItems){
        //Resetear el listado de Artículos
        //commit('updatesPageOffset', 0);
        //console.log(newItems);
        dispatch('updatePrices',newItems);
        //commit('updateAppState', 'ready');
        commit('updatesItemsList', newItems);
        commit('updateItemFlag');
        commit('updateCarAmount',getters);
    },
    addGlobalFilter : function ({commit,state,dispatch},filter){
        //console.log('ADD GLOBLA FILTER');
        commit('clearItemList');
        commit('restoreFilter');
        //commit('resetGlobalFilter');
        let newitems = [];
        for(let key of state.items_store.keys()){
            let isPresent = RegExp(filter.label, 'gi');
            let x = state.items_store.get(key);
            try {
                let isVisible = false;
                if(filter.type == "rangeFilter"  || filter.type == "inputSearch" || filter.type == "genericFilter") {
                    isVisible = eval(filter.condition) == filter.result;
                }else {
                    if (x[filter.filterField] != null) {
                        isVisible = isPresent.test(x[filter.filterField]);
                    }
                }
                //console.log('filter ',filter.label,filter.filterField,x[filter.filterField],isPresent,isVisible);
                if (isVisible)
                    newitems.push(x);

            }
            catch (err) {
                // eslint-disable-next-line
                console.group(x.Code);
                // eslint-disable-next-line
                console.log('Error filter item');
                // eslint-disable-next-line
                console.log("Item",x);
                // eslint-disable-next-line
                console.log('Filter',filter);
                // eslint-disable-next-line
                console.log('Error',err);
                // eslint-disable-next-line
                console.groupEnd(x.Code);
            }
        }
        //console.log('Item By Global Filter',newitems);
        dispatch('beforeChangeItems',newitems);
        //Test Only
        //state.filters=[]
        //state.filtersObjects=new Map()
        commit('addFilter',filter);

    },
    addFilter : function ({commit,state,dispatch},filter){
        if(!state.filtersObjects.has(filter.name)) {
            let  filterItems = state.items;
            for(let x of filterItems){
                let isPresent = RegExp(filter.label, 'gi');
                if(filter.type == "classificationFilter") {
                    isPresent = RegExp(filter.code, 'gi');
                }
                if(filter.type == "globalClassification")
                    dispatch('updateCategory',filter.label);

                if(x.visible == true) {
                    if(filter.type == "rangeFilter") {
                        x.visible = eval(filter.condition) == filter.result;
                    }else {
                        if (x[filter.filterField] != null) {
                            x.visible = isPresent.test(x[filter.filterField]);
                        }
                    }
                    if (!x.visible && !filter.hiddens.has(x.Code))
                        filter.hiddens.set(x.Code,true);
                }
            }
            commit('addFilter',filter);
            commit('updatesItemsList',filterItems);
            commit('updateItemFlag');
        }
        else{

            let  filterItems = state.items;
            let isPresent = RegExp(filter.label, 'gi');
            let storeFilter = state.filtersObjects.get(filter.name);
            let keepHidden = new Map();
            for (let infilter of Array.from(state.filtersObjects.values())){
                if(infilter.hiddens && infilter.name != filter.name){
                    for(let itemCode of Array.from(infilter.hiddens.keys()))
                        keepHidden.set(itemCode,true);
                }
            }
            for(let x of filterItems){
                if(x.visible == false && storeFilter.hiddens.has(x.Code) && !keepHidden.has(x.Code)) {
                    let visibility = true;
                    for(let activeFilter of state.filtersObjects.values()) {
                        if(activeFilter.name != filter.name) {
                            if (x[filter.label] != null) {
                                if(filter.type == "'rangeFilter'") {
                                    visibility = eval(filter.condition) == filter.result;
                                }else {
                                    visibility = isPresent.test(x[filter.label]);
                                }
                            }
                        }
                    }
                    x.visible = visibility;
                }
            }
            //console.log('Remove filter',filterItems);
            dispatch('removeFilter',filter.name);
            //dispatch('updateCategory',null);
            commit('updatesItemsList',filterItems);
            commit('updateItemFlag');
        }
    },
    filterItem: function ({commit,state,dispatch},params){
        //console.log("filterItem");
        let newitems = [];
        for(let x of  state.items_store.values()) {
            try {
                if (eval(params.filters[0].condition) == params.filters[0].result)
                    newitems.push(x);
            }
            catch (err) {
                // eslint-disable-next-line
                console.log('Error in filter Items',x);
            }
        }
        if(newitems.length > 0)
            dispatch('beforeChangeItems', newitems);
    },
    removeFilter:function ({commit,state,dispatch},filter){
        //console.log("removeFilter");
        state.filters = state.filters.filter(e => e !== filter);
        state.filtersObjects.delete(filter);
        commit('updateFilters',state.filters);
        commit('updateFiltersObjects',state.filtersObjects);
    },
    loadLanguage: async function ({commit,state},language = 'es'){
        // eslint-disable-next-line
        let response = await axios.get('/ecommerce/api/getLanguage');
        let languageDict = response.data;
        commit('setLanguage',languageDict.dict);
    },
    loadSession:async function ({commit,state,getters,dispatch},root = null,lvl = 0){
        return new Promise(async (resolve, reject) => {
            //console.log('Loading Session');
            commit('updateAppState', 'onload');
            // eslint-disable-next-line
            let response = await axios.get('/ecommerce/api/getInitFetch', {
                method: "GET",
                credentials: 'include'
            });
            let objResponse = response.data;
            if (objResponse.sessionPerson) {
                commit('updateIsLogin', true);
                commit('updateSession', objResponse.sessionPerson);
                commit('updateGenericUser', objResponse.sessionPerson.fields.RequireUserInfo);
                commit('updateRequiredPurchaseOrder', objResponse.requirePurchaseOrder);

                if(objResponse.sessionPerson.fields.CarInfo) {
                    let sessionCartItems = [];
                    for (let carItem of objResponse.sessionPerson.fields.CarInfo.items) {
                        if(carItem.item)
                            delete carItem.item;
                        sessionCartItems.push(carItem);
                        //dispatch('ItemComponentAddToCar',{id:this.item.Code,cant:this.Qty})
                    }
                    commit('updateSessionCar',sessionCartItems);
                }
            }

            commit('updateIsLiberatorOrder', objResponse.isLiberator);
            commit('updateIsAdmin', objResponse.isAdmin);
            commit('updateIsLiberatorOrder', objResponse.LiberatorCodes);
            commit('updateIsOnlyModifier', objResponse.OnlyModifiedCodes);

            if(objResponse.synonyms)
                commit('updateSynonym',objResponse.synonyms);

            if (objResponse.customerGifts)
                commit('updateGifts', objResponse.customerGifts);

            /*if (objResponse.carInfo){
                    //console.log('Car from session',objResponse.carInfo);
                    state.items_in_car=objResponse.carInfo.items;
                    state.items_in_map =ArrayToMap(objResponse.carInfo.items,'id');
                }*/

            if (objResponse.vatCodeMap) {
                commit('updateVatCode',objResponse.vatCodeMap);
            }

            if (objResponse.classifications)
                commit('updateCategoryMap', objResponse.classifications);

            if (objResponse.classificationsTree)
                commit('updatesCatList', objResponse.classificationsTree);

            if (objResponse.setting){
                commit('updateSetting', objResponse.setting.fields);
                let defaultOrdered = objResponse.setting.fields.ItemOrdered.filter(x=>x.fields.ByDefault);
                if(defaultOrdered.length > 0){
                    commit('setOrderBy', defaultOrdered[0].fields.ItemField);
                    commit('setOrderDirection', defaultOrdered[0].fields.OrderDirection);
                }

            }

            if (objResponse.customer)
                commit('updateCustomer', objResponse.customer.fields);

            if (objResponse.salesman)
                commit('updateSalesMan', objResponse.salesman.fields);

            if (objResponse.orderTemplate)
                commit('updateOrderTemplate', objResponse.orderTemplate.fields);

            if (objResponse.editStates)
                commit('updateOrderEditableStates', objResponse.editStates);
            if (objResponse.translateDict)
                commit('updateTranslate', objResponse.translateDict);

            if (objResponse.priceDeal)
                commit('updatePriceDeal', objResponse.priceDeal);

            if (objResponse.discountDeal)
                commit('updateDiscountDeal', objResponse.discountDeal);

            if (objResponse.items && objResponse.items.length > 0) {
                let fields = Object.keys(objResponse.items[0]);
                //console.log('Before Lodash',objResponse.items);
                let items = window.__lodash__.map(objResponse.items, (x) => {
                    x.fieldToSearch = "";
                    x.SpecialPrice = objResponse.priceDeal.prices[x.Code] != undefined;

                    x.BasePrice = x.BasePrice || x.Price;
                    x.VATPrice = x.VATPrice || null;
                    x.RowNet = x.RowNet || null;
                    x.RowTotal = x.RowNet || null;
                    x.isCalculate = x.isCalculate || false;

                    //x.visible = x.visible || false;
                    for (let field of fields) {
                        x.fieldToSearch += x[field];
                    }
                    return x;
                });
                //console.log('AfterBefore Lodash',items);
                //console.warn("Load Items Init",items);
                commit('updatesItemsStore', items);
                dispatch('applyRestrictions',items);
            }

            if (objResponse.favorites) {
                commit('updateFavoriteList',objResponse.favorites);
            }

            if (objResponse.suggestions)
                commit('updateSuggestions', objResponse.suggestions);


            dispatch('updateCarAmount');
            commit('updateAppState', 'ready');
            resolve(true);
        });
    },
    reorderItems:function ({commit,state,dispatch},fieldId){
        for(let field of state.setting.ItemOrdered){
            if(field.fields)
                field = field.fields;
            if(field.internalId == fieldId){
                commit('setOrderBy',field.ItemField);
                commit('setOrderDirection',field.OrderDirection);
                //commit('updateItemFlag');
                break;
            }
        }
    },
    login:function ({commit,state,dispatch},params){
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'post',
                url: '/ecommerce/api/login',
                data: {username: params.username, md5password: params.password}
            })
                .done(function (response) {
                    let objResponse = JSON.parse(response);
                    if (objResponse.ok) {
                        commit('updateIsLogin', true);
                        dispatch('loadSession');
                        commit('updateItemFlag');
                    }
                    resolve(objResponse);
                }).fail(function (error) {
                    resolve({ok:false});
                    commit('updateItemFlag');
                });
        });
    },
    logout:function ({commit,state,dispatch}) {
        return new Promise((resolve, reject) => {
            $.ajax({url: '/ecommerce/api/logout'})
                .done(function (response) {
                    let objResponse = JSON.parse(response);
                    commit('updateFavoriteList',[]);
                    commit('updateIsLogin', false);
                    commit('updateGenericUser', false);
                    commit('updateSession', objResponse.session);
                    commit('clearItemToCar', 'result');
                    dispatch('loadSession');
                    commit('updateItemFlag');
                    resolve({ok:false});
                }).fail(function (error) {
                    // eslint-disable-next-line
                    alert('Error when try to close session');
                    resolve({ok:false});
                });
        });
    },
    changeItemViewMode:function ({commit,state,dispatch},itemMode){
        commit('setItemViewMode',itemMode);
    },
    updateCategory:function ({commit,state},category){
        commit('updateCategory',category);
    },
    updateCategoryHistory:function ({commit,state},parameters){
        commit('updateCategoryHistory',parameters);
    },
    loadCategories:function ({commit,state},root = null,lvl = 0){
        $.ajax({url:'/ecommerce/api/getCategories',data:{root:root,lvl:lvl}}).done(function (response){
            commit('updatesCatList',response);
        }).fail(function (error) {
            //console.log('Error response in LoadCategories',error);
        });
    },
    restoreFilter: function ({commit,state}){
        commit('restoreFilter');
        commit('updatesItemsList', []);
        commit('updateItemFlag');
    },
    restoreHistory: function ({commit,state}){
        commit('restoreHistory');
    },
    toggleFavourite:function ({commit,dispatch,state},itemCode){
        $.ajax({
            url: '/ecommerce/api/favorite/' + itemCode,
            method: "POST",
            data: {favorite: !this.favorite}
        }).done(function (response) {
            //Asignar respuesta a el favorites.
            if (response.resfav == "add" && response.saved == true)
                commit('addFavorite', itemCode);
            else
                commit('removeFavorite', itemCode);
                //commit('updateAppState','ready');
        }).fail(function (error) {
            // eslint-disable-next-line
            console.log(error);
            //alert('error in favorite request')
        });
    },
    loadItems: function ({commit,state,dispatch},category = ''){
        if(category != '') {
            $.ajax({
                url: '/ecommerce/api/getItems',
                data: {action: 'load', cat: category, offset: state.pageOffset, items_per_page: state.items}
            }).done(function (response) {
                let items = JSON.parse(response);
                commit('updatesItemsList', items);
                commit('updateItemFlag');
            }).fail(function (error) {
                // eslint-disable-next-line
                console.log('Error response in LoadCategories', error);
            });
        }
        else
            dispatch('searchItems');
    },
    loadAlias: function ({commit,state},aliasCode){
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line
            axios.post('/ecommerce/api/getFilterAlias', {
                aliascode: aliasCode,
            })
                .then(function (response) {
                    resolve(response.data.record);
                })
                .catch(function (error) {
                    // eslint-disable-next-line
                    console.log('Error response in Load Alias Filter ', error);
                    reject(error);
                });
        });
    },
    ItemComponentAddToCar:function ({commit,dispatch},carObject){
        commit('updateItemToCar',carObject);
        dispatch('updateCarAmount');
    },
    changeCarStatus:function ({commit,state},newMode){
        //console.log('Change Status',newMode);
        commit('updateCarStatus',newMode);
    },
    clearItemToCar:function ({commit,state}){
        commit('clearItemToCar','view');
    },
    createSaleOrder:function ({commit,state,dispatch},payload){
        if(!payload)
            payload = {};

        payload['DelAddressCode'] = state.order_DelAddressCode;
        if(!payload['Comment'])
            payload['Comment'] = state.order_Comment;
        payload['DelDate'] = state.order_DelDate;

        if(state.order_PayTerm)
            payload['PayTerm'] = state.order_PayTerm;
        if(state.order_CenterCost)
            payload['CenterCost'] = state.order_CenterCost;

        return new Promise((resolve, reject) => {
            let tempItems = [];
            for (let item of state.items_in_car) {
                //console.log(item);
                tempItems.push({Code: item.id, Cant: item.cant});
            }
            // eslint-disable-next-line
            axios.post('/ecommerce/api/createSalesOrder', {
                items: tempItems,
                extras: payload
            })
                .then(function (response) {
                    if (response.data.success) {
                        commit('clearItemToCar', 'result');
                        dispatch('saveCarSession');
                    }
                    resolve(response.data);
                })
                .catch(function (error) {
                    // eslint-disable-next-line
                    console.log('Error response in LoadCategories', error);
                    commit('clearItemToCar', 'failure');
                    reject(error);
                });
        });
    },
    loadOrderHistory:async function ({commit,state},filter){
        return new Promise((resolve, reject)=> {
            $.ajax({dataType: "json", url: '/ecommerce/api/getOrders', data: {filter: filter}})
                .done(function (response) {
                    commit('updateOrderEditableStates', response.editStates);
                    commit('updateOrderList', response.list);
                    commit('updateIsLiberatorOrder', response.isLiberator);
                    resolve(true);
                }).fail(function (error) {
                    //console.log('Error response in LoadSaleOrders',error);
                    resolve(false);
                });
        });
    },
    orderDetail:function ({commit,state},orderid){
        return new Promise((resolve,reject)=>{
            $.ajax({dataType: "json", url: '/ecommerce/api/getOrderDetail', data: {orderid: orderid}})
                .done(function (response) {
                    commit('updateCurrentOrder', response.order);
                    if(response.order)
                        commit('updateIsLiberatorOrder', state.liberatorCodes.indexOf(response.order.fields.Contact) != -1);
                    resolve(response);
                }).fail(function (error) {
                    //console.log('Error response in LoadSaleOrders',error);
                    resolve(false);
                });
        });
    },
    copyOrder:function ({commit,state,dispatch},params){
        commit('clearItemToCar');
        $.ajax({dataType: "json",url:'/ecommerce/api/getOrderDetail',data:{orderid:params.orderId}})
            .done(function (response){
                if(response.order) {
                    commit('updateCurrentOrder', response.order);
                    let itemList = [];
                    for (let item of response.order.fields.Items) {
                        let itemStore = state.items_store.get(item.fields.ArtCode);
                        if(itemStore) {
                            itemStore['cant'] = item.fields.Qty;
                            itemList.push(itemStore);
                        }
                    }
                    //console.log('Copy Action',itemList);
                    let itemCar = [];
                    for (let item of itemList) {
                        itemCar.push({id:item.Code, cant:item.cant});
                    }
                    dispatch('updatePrices', itemList);
                    // eslint-disable-next-line
                    state.items_in_map = ArrayToMap(itemCar,'id');
                    state.items_in_car = itemCar;
                    dispatch('updateCarAmount');
                }
                else{
                    dispatch('showNotificacion', {
                        title: 'Acción no permitida',
                        message: 'Está intentando ver / copiar un pedido que no fue creado por usted o su Grupo de Clientes',
                        type: 'error'
                    });
                    //alert(`You are try to view/copy a order that aren't created for you or your CustomerGroup`)
                }
            })
            .fail(function (error) {
                //console.log('Error response in LoadSaleOrders',error);
            });
    },
    orderAction:function ({commit,state,dispatch},params){
        //commit('clearItemToCar');
        let salesmanText = `${state.salesman.Name} ${state.salesman.LastName} (${state.salesman.Phone}) (${state.salesman.Email})`;
        let serNr = params["decodeSerNr"];
        params["decodeSerNr"] = null;
        $.ajax({dataType: "json",url:'/ecommerce/api/orderAction',data:params})
            .done(function (response){
                if(response.success){
                    let newOrderList = [];
                    for(let order of state.order_list)
                        if(order.SerNr != serNr)
                            newOrderList.push(order);
                    commit('updateOrderList',newOrderList);
                    commit('updateCurrentOrder', response.so);
                    if(params.action == 'liberate')
                        // eslint-disable-next-line
                        alert(`La orden de venta ${serNr} ha sido "APROBADA". Se le ha enviado un correo electrónico con la notificación de la aprobación al usuario solicitante. Ante cualquier duda, favor de comunicarse con su ejecutivo de cuentas: ${salesmanText}`);
                    if(params.action == 'reject')
                        // eslint-disable-next-line
                        alert(`La orden de venta ${serNr} ha sido "RECHAZADA". Se le ha enviado un correo electrónico con la notificación del rechazo al usuario solicitante. Ante cualquier duda, favor de comunicarse con su ejecutivo de cuentas: ${salesmanText}`);
                        //dispatch('orderDetail',state.currentOrder.fields.SerNr);
                }
                else {
                    // eslint-disable-next-line
                    alert(`La acción que intenta realizar no se ha podido procesar Por favor volver a intentarlo  o favor de comunicarse con su ejecutivo de cuentas: ${salesmanText}`);
                }
            }).fail(function (error) {
                //console.log('Error response in OrderAction',error);
            });
    },
    changeCartQty:function ({commit,state,dispatch},params){
        let newState = new Map(state.items_in_map);
        switch (params.action) {
            case 'up':
                newState.get(params.itemCode).cant = parseInt(newState.get(params.itemCode).cant) + 1;
                break;
            case 'down':
                if (newState.get(params.itemCode).cant > 1)
                    newState.get(params.itemCode).cant = parseInt(newState.get(params.itemCode).cant) - 1;
                break;
            case 'delete':
                newState.delete(params.itemCode);
                break;
            case 'update':
                newState.get(params.itemCode).cant = parseInt(params.itemQty);
                break;
        }
        commit('updateCarQty',newState);
        dispatch('updateCarAmount');
    },
    saveCurrentOrder: function ({commit, state, dispatch}) {
        return new Promise((resolve, reject) => {
            let params = {};
            params.sernr = state.currentOrder.fields.SerNr;
            params.items = {};
            params.newItems = [];
            for (let item of state.currentOrder.fields.Items)
                params.items[item.fields.internalId] = {ArtCode: item.fields.ArtCode, Qty: item.fields.Qty,internalId: item.fields.internalId};
            if(state.currentOrder.fields.NewItems)
                params.newItems = state.currentOrder.fields.NewItems;
            let salesmanText = `${state.salesman.Name} ${state.salesman.LastName} (${state.salesman.Phone}) (${state.salesman.Email})`;
            if(state.salesman)
                salesmanText = `${state.salesman.Name} ${state.salesman.LastName} (${state.salesman.Phone}) (${state.salesman.Email})`;
            // eslint-disable-next-line
            axios.post('/ecommerce/api/saveOrder', params)
                .then(function (response) {
                    response = response.data;
                    if(response.success){
                        commit('updateCurrentOrder',response.so);
                        commit('updateCarStatus','view');
                        commit('clearItemToCar');
                        //Materialize.toast(, 150000, 'rounded');
                        // eslint-disable-next-line
                        alert(`La orden de venta ${params.sernr} ha sido "MODIFICADA Y GUARDADA". Favor recordar que la misma deberá ser aprobada para ser procesada. Ante cualquier duda, favor de comunicarse con su ejecutivo de cuentas: ${salesmanText}.`);
                    }
                    else{
                        // eslint-disable-next-line
                        alert(`La orden de venta ${params.sernr} no pudo ser "MODIFICADA O GUARDADA". Favor de comunicarse con su ejecutivo de cuentas: ${salesmanText}.`);
                    }
                    dispatch('orderDetail',state.currentOrder.fields.SerNr);
                    resolve(response);
                })
                .catch(function (error) {
                    // eslint-disable-next-line
                    console.log(error);
                    // eslint-disable-next-line
                    alert(`La orden de venta ${params.sernr} no pudo ser "MODIFICADA O GUARDADA". Favor de comunicarse con su ejecutivo de cuentas: ${salesmanText}.`);
                    commit('updateCarStatus','view');
                    resolve(null);
                });
        });
    },
    deleteItemCurrenOrder:function ({commit,state,dispatch},item){
        let currentOrder = Object.assign({},state.currentOrder);
        for(let i = 0;i < currentOrder.fields.Items.length;i++) {
            if (currentOrder.fields.Items[i].fields.internalId == item.fields.internalId) {
                currentOrder.fields.Items.splice(i, 1);
                break;
            }
        }
    },
    updateItemToOrder:function ({commit,state,dispatch}){
        commit('updateCarStatus','editOrder');
        commit('updateItemToOrder');
        dispatch('updateCarAmount');
    },
    updateUserInfo:function ({commit,state,dispatch},params){
        return new Promise((resolve, reject) => {
            $.post('/ecommerce/api/updatePerson', params)
                .done(function (response) {
                    response = JSON.parse(response);
                    dispatch('updateGenericUser',!response.success);
                    dispatch('updateSession',response.person);
                    resolve(response);
                })
                .fail(function (error) {
                    // eslint-disable-next-line
                    console.log('Error response in Update UserInfo', error);
                    dispatch('updateGenericUser',false);
                    resolve(error);
                });
        });
    },
    updateCarAmount:function ({commit,getters,dispatch}){
        commit('updateCarAmount',getters);
    },
    applyRestrictions:function ({state,commit,getters,dispatch},items){
        //console.log('View Restrictions',state.items,items)
        let applyViewRestrictions = [];
        for(let item of state.items) {
            if (state.items_store.has(item.Code))
                applyViewRestrictions.push(item);
        }
        commit('updatesItemsList',applyViewRestrictions);
        //state.items=applyViewRestrictions;
        let applyViewRestrictionsCars = [];
        for(let item of state.items_in_car) {
            if (state.items_store.has(item.id))
                applyViewRestrictionsCars.push(item);
        }
        // eslint-disable-next-line
        state.items_in_map = ArrayToMap(applyViewRestrictionsCars,'id');
        state.items_in_car = applyViewRestrictionsCars;
        dispatch('updateCarAmount');
    },
    saveCarSession:function ({state}){
        // eslint-disable-next-line
        axios.post('/ecommerce/api/saveCarSession', {'items':JSON.stringify(state.items_in_car)});
    },
    updateCurrentPageOffset:function ({state,commit},offset){
        commit('updatesPageOffset',offset);
    },
    updateCurrentPage:function ({state,commit},currentPage){
        commit('updatePage',currentPage);
    },
    updateOrderCenterCost:function ({commit, state, dispatch}, centerCost){
        commit('updateOrderCenterCost',centerCost);
    },
};
let defaultMutations = {
    updateRequiredPurchaseOrder(state,requiredPurchaseOrder){
        state.requiredPurchaseOrder = requiredPurchaseOrder;
    },
    updateIsLiberatorOrder(state, isLiberator){
        if(Array.isArray(isLiberator)){
            state.liberatorCodes = isLiberator;
            state.order_can_liberate = isLiberator.length > 0;
        }
        else
            state.order_can_liberate = isLiberator;
    },
    updatePage(state,currentPage){
        state.currentPage = currentPage;
    },
    updatesPageOffset(state,offset){
        state.pageOffset = offset;
    },
    setItemViewMode(state,itemMode){
        state.viewItemMode = itemMode;
    },
    setOrderBy(state,newField){
        state.orderBy = newField;
    },
    setOrderDirection(state,newField){
        state.orderDirection = newField;
    },
    setLanguage(state,newDict){
        state.translateDict = Object.freeze(newDict);
    },
    updateCarQty(state,newState){
        state.items_in_car = Array.from(newState.values());
        state.items_in_map = newState;
    },
    updateIsLogin(state,SessionisLogin){
        state.isLoggin = SessionisLogin;
    },
    addFilter(state,newFilter){
        state.filtersObjects.set(newFilter.name,newFilter);
        state.filters.push(newFilter.name);
    },
    removeFilter(state,newFilter){
        state.filtersObjects.delete(newFilter.name);
        state.filters.pop();
    },
    updateSetting(state,newValue){
        state.setting = newValue;
    },
    updateSession(state,newSession){
        state.session = newSession;
    },
    updateSynonym(state,newSynonyms){
        state.synonyms = newSynonyms;
    },
    updateGenericUser(state,newValue){
        state.mustUpdateInfo = newValue;
    },
    updateSalesMan(state,salesMan){
        state.salesman = salesMan;
    },
    updateCustomer(state,customer){
        state.customer = customer;
    },
    updateCurrentOrder(state,newOrder){
        state.currentOrder = newOrder;
    },
    updateIsAdmin(state,isAdmin){
        state.isAdmin = isAdmin;
    },
    updateOrderList(state,newValue){
        state.order_list = newValue;
    },
    updateOrderTemplate(state,orderTemplate){
        state.orderTemplate = orderTemplate;
    },
    updateOrderEditableStates(state,orderStates){
        state.order_editable_states = orderStates;
    },
    updateVatCode(state,vatCodeList){
        for(let vatCode of vatCodeList)
            state.vatCodeMap.set(vatCode.Code,vatCode);
    },
    updateOrderCenterCost(state,newCenterCost){
        state.order_CenterCost = newCenterCost;
    },
    updateTranslate(state,newDict){
        state.translateDict = newDict;
    },
    updatePriceDeal(state,priceDeal){
        state.priceDeal = priceDeal;
    },
    updateDiscountDeal(state,discountDeal){
        state.discountDeal = discountDeal;
    },
    toogleCarModal(state){
        state.show_CarModal = !state.show_CarModal;
    },
    updateFavoriteList(state,favoriteList){
        state.favorites = favoriteList;
    },
    addFavorite(state,itemCode){
        if (state.favorites.indexOf(itemCode) == -1) {
            state.favorites.push(itemCode);
        }
    },
    removeFavorite(state,itemCode){
        let newFavorites = [];
        for(let fav of state.favorites)
            if(fav != itemCode)
                newFavorites.push(fav);
        //console.log('New',newFavorites);
        state.favorites = newFavorites;
    },
    updatesCatList(state,categories){
        state.cat_list = categories;
    },
    updatesItemsStore(state,items){
        //console.log('items in session',Object.freeze(items));
        // eslint-disable-next-line
        state.items_store = ArrayToMap(items,'Code');
        state.itemTimeFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    updateItemsInStore(state,itemList){
        for(let item of itemList)
            state.items_store.set(item.Code, item);
    },
    addItemsList(state,newItems){
        if(Array.isArray(newItems)) {
            state.pageOffset = 0;
            state.items = state.items.concat(newItems);
        } else{
            // eslint-disable-next-line
            console.log('Error Type is not Array',newItems);
        }
        // eslint-disable-next-line
        state.items_visible = __lodash__.orderBy(state.items.filter(x => x.visible), state.orderBy,state.orderDirection);
        state.itemTimeFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    updatesItemsList(state,newItems) {

        //console.log('Muting Items List', newItems);
        if (Array.isArray(newItems)) {
            state.pageOffset = 0;
            state.items = newItems;

        }else{
            // eslint-disable-next-line
            console.log('Error Type is not Array',newItems);
        }
        // eslint-disable-next-line
        state.items_visible = __lodash__.orderBy(state.items.filter(x => x.visible), state.orderBy,state.orderDirection);
        state.itemTimeFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    clearItemList(state) {
        state.items = [];
        state.items_visible = [];
        state.itemTimeFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    resetGlobalFilter(state){
        state.resetFilters = Math.floor((Math.random() * 1000000) + 1);
    },
    updateItemFlag(state){
        // eslint-disable-next-line
        state.items_visible = __lodash__.orderBy(state.items.filter(x => x.visible), state.orderBy,state.orderDirection);
        state.itemUpdate = Math.floor((Math.random() * 1000000) + 1);
        state.itemTimeFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    updateItemItemFlag(state,newValue){
        // eslint-disable-next-line
        state.items_visible = __lodash__.orderBy(state.items.filter(x => x.visible), state.orderBy,state.orderDirection);
        state.itemTimeFlag = Math.floor((Math.random() * 1000000) + 1);
    },
    updateAppState(state,newState){
        state.appState = newState;
        /*if(newState=='ready')
                clearTimeout(loopWaiting)*/
    },
    updateItemToOrder(state){
        if(state.cartStatus == 'editOrder' && state.currentOrder) {
            for(let carItem of state.items_in_car){
                let item = state.items_store.get(carItem.id);
                let newArt = true;
                if(!state.currentOrder.fields.NewItems)
                    state.currentOrder.fields.NewItems = [];
                for(let art of state.currentOrder.fields.Items)
                    if(art.fields.ArtCode == item.Code){
                        art.fields.Qty += carItem.cant;
                        art.fields.RowTotal = item.VATPrice * art.fields.Qty;
                        art.fields.RowNet = item.Price * art.fields.Qty;
                        newArt = false;
                    }
                if(newArt) {
                    let url = '/ecommerce/api/getPrices';
                    let personCode = state.session ? state.session.Code : null;
                    let customerCode = state.customer ? state.customer.Code : null;
                    //eslint-disable-next-line
                    axios.post(url, {artCodes: [item.Code], personCode: personCode, customerCode: customerCode})
                        .then((response) => {
                            let data = response.data;
                            let dataItem = data[item.Code];
                            state.currentOrder.fields.NewItems.push({
                                fields: {
                                    ArtCode: item.Code,
                                    Name: item.Name,
                                    Price: dataItem.Price,
                                    VATPrice: dataItem.VATPrice,
                                    RowTotal: dataItem.RowTotal * carItem.cant,
                                    RowNet: dataItem.RowNet * carItem.cant,
                                    AlternativeCode: item.AlternativeCode,
                                    Qty: carItem.cant
                                }
                            });
                            state.currentOrder.fields.Items.push({
                                fields: {
                                    ArtCode: item.Code,
                                    Name: item.Name,
                                    Price: dataItem.Price,
                                    VATPrice: dataItem.VATPrice,
                                    RowTotal: dataItem.RowTotal * carItem.cant,
                                    RowNet: dataItem.RowNet * carItem.cant,
                                    AlternativeCode: item.AlternativeCode,
                                    Qty: carItem.cant
                                }
                            });
                        })
                        .catch((err) => {
                        });
                }
            }
        }
    },
    updateItemToCar(state,carObject){
        if(state.items_in_map.has(carObject.id))
            state.items_in_map.get(carObject.id).cant = parseInt(state.items_in_map.get(carObject.id).cant) + parseInt(carObject.cant);
        else
            state.items_in_map.set(carObject.id,carObject);

        state.items_in_car = Array.from(state.items_in_map.values());
    },
    updateCarAmount(state,getters){
        let carAmount = 0.0;
        let carAmountIVA = 0.0;
        for(let item of state.items_in_car){
            let itemStore = state.items_store.get(item.id);
            //console.log(itemStore,item.cant,itemStore.RowNet,itemStore.RowTotal);
            if(!itemStore) {
                continue;
            }
            if(isNaN(item.cant))
                item.cant = 0;
            carAmount = carAmount + parseFloat(item.cant) * itemStore.RowNet;
            carAmountIVA = carAmountIVA + parseFloat(item.cant) *  itemStore.RowTotal;
        }
        state.total_in_car = carAmount.toFixed(2);
        state.total_in_car_iva = carAmountIVA.toFixed(2);
    },
    clearItemToCar(state,status){
        state.items_in_map = new Map();
        state.items_in_car = [];
        state.total_in_car = 0.0;
        state.total_in_car_iva = 0.0;
        if(status)
            state.cartStatus = status;
    },
    updateCarStatus(state,newStatus){
        //console.log('Updare Car Status',newStatus);
        state.cartStatus = newStatus;
    },
    updateSessionCar(state, itemCart){
        for(let item of itemCart) {
            //console.log(item);
            state.items_in_map.set(item.id, item);
        }
        state.items_in_car = Array.from(state.items_in_map.values());
    },
    updateCategoryMap(state,catList){
        let nameMap = new Map();
        let codeMap = new Map();
        for(let cat of catList){
            if(!nameMap.has(cat.name))
                nameMap.set(cat.name,cat);
            if(!codeMap.has(cat.code))
                codeMap.set(cat.code,cat);
        }
        state.cat_by_name = Object.freeze(nameMap);
        state.cat_by_code = Object.freeze(codeMap);
    },
    updateCategory(state,category){
        let history = [];
        if(category) {
            // eslint-disable-next-line
            GetCategoryPath(category.code,state.cat_list[0],history);
        }
        state.cat_selected = category;
        //console.log('Cat History',category,history);
        state.cat_history = history.slice(1).reverse();
    },
    updateCategoryHistory(state,parameters){
        //console.log('History TEst',parameters)
        /*if(parameters.temp) {
                state.cat_history_temp = state.cat_history_temp.filter((x) => {
                    return x.lvl < parameters.lvl;
                });
                parameters.category.lvl = parameters.lvl;
                state.cat_history_temp.push(parameters.category);
            }
            else{
                state.cat_history= state.cat_history_temp;
            }*/
    },
    updateGifts(state,customerGifts){
        state.customerGifts = customerGifts;
    },
    updateSuggestions(state,suggestions){
        state.suggestionList = suggestions;
    },
    restoreHistory(state){
        state.cat_history = [];
        state.cat_history_temp = [];
    },
    restoreFilter(state){
        state.filters = [];
        state.filtersObjects = new Map();
        for(let item of state.items_store.values()){
            item.visible = true;
        }
        state.items = [];
        state.items_visible = [];
        state.resetFilters = Math.floor((Math.random() * 1000000) + 1);
    }

};

const store = new Vuex.Store({
    strict: false,
    // eslint-disable-next-line
    state: _.cloneDeep(defaultState),
    // eslint-disable-next-line
    getters: _.cloneDeep(defaultGetters),
    // eslint-disable-next-line
    actions: _.cloneDeep(defaultActions),
    // eslint-disable-next-line
    mutations: _.cloneDeep(defaultMutations),
});